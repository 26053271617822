import './App.css';
import Footer from "./components/Footer";
import Header from "./components/Header";
import { Outlet } from "react-router-dom";

export async function loader() {
  return null; 
}

function App() {
  return (
     <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
}

export default App;